import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import './App.scss';
import 'react-bootstrap/Row';
import 'react-bootstrap/Col';
import { CirclesWithBar } from  'react-loader-spinner'
import { Alert } from 'react-bootstrap';

const types = [{
  title: 'Level test appointment',
  subtitle: 'Appointments will take approximately 1 hour'
}];

const host = 'https://essentialenglishcentre.unlockideas.com'
// const host = `http://127.0.0.1:3205`
const token = `be3a3d7e-a5a8-4ac6-9e52-84c9e8674bbd`

function App() {
  // react-router-dom get query params
  const { uuid } = useParams();
  const [availability, setAvailability] = useState([])
  const [selectedDate, setSelectedDate] = useState(0)
  const [selectedTime, setSelectedTime] = useState(0)
  const [selectedApptType, setSelectedApptType] = useState(types[0])
  const [timeslots, setTimeslots] = useState([])
  const [requestError, setRequestError] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [telephone, setTelephone] = useState('')
  const [uuidValid, setUuidValid] = useState(null)
  const [booked, setBooked] = useState('')
  const [loading, setLoading] = useState(false)

  const onSelectDate = (date) => {
    setSelectedDate(date)
    setSelectedTime(null)
  }

  const onSelectTime = (time) => {
    if (time.disabled) {
      return;
    }
    setSelectedTime(time.time)
  }

  const resetCal = () => {
    setSelectedDate(null)
    setSelectedTime(null)
    setTimeout(() => {
      document.getElementById('dates-0').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200)
  }

  const onCancelBooking = () => {
    if (uuid && uuid.length > 0) {
      setRequestError(null)
      setLoading(true)
      axios.post(`${host}/api/booking/cancel/${uuid}`, {
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(({status, data}) => {
        resetCal();
        setLoading(false)
      }).catch(handleError)
    } else {
      setRequestError('No UUID Found')
    }
  }

  const onApptType = (apptType) => {
    setSelectedApptType(apptType)
    resetCal();
  }

  const slideNavPress = (dir) => {
    setSelectedTime(null)
    let indx = 0
    for (let av of availability) {
      const match = _.find(av, a => a.date_str === selectedDate.format('YYYY-MM-DD'))
      if (match) {
        break
      }
      indx++
    }
    let newIndx = indx + (dir === 'prev' ? -1 : 1)
    newIndx = newIndx < 0 ? 0 : newIndx
    setSelectedDate(availability[newIndx][0].date)
  }

  const handleError = err => {
    let error = _.get(err, 'response.data.message')
    if (!error) {
      error = _.get(err, 'response.data.errors[0]')
    }
    if (!error) {
      error = _.get(err, 'response.data.description')
    }
    if (!error) {
      error = _.get(err, 'message')
    }
    if (!error) {
      error = 'Something went wrong, please try again later, or contact us for assistance.'
    }
    setRequestError(error)
    setLoading(false)
  }

  const onSubmitBooking = () => {
    if (uuid && uuid.length > 0 && selectedDate && selectedTime && name.length > 0 && email.length > 0 && telephone.length > 0) {
      setRequestError(null)
      setLoading(true)
      axios.post(`${host}/api/booking/book/${uuid}`, {
        type: selectedApptType.title,
        date: selectedDate.format('YYYY-MM-DD'),
        time: selectedTime,
        name,
        email,
        telephone
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(({status, data: { result: { availability, entity } } }) => {
        window.location.reload(true)
        setLoading(false)
      }).catch(handleError)
    } else {
      setRequestError('Please fill in all fields')
    }
  }

  useEffect(() => {
    if (uuid && uuid.length > 0) {
      setRequestError(null)
      setLoading(true)
      axios.get(`${host}/api/booking/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(({status, data: { result: { availability, entity } } }) => {
        let i=0
        for (let av of availability) {
          let ii=0
          for (let d of av) {
            d.date = moment(d.date).clone()
            av[ii] = d
            ii++
          }
          availability[i] = av
          i++
        }
        setAvailability(availability);
        if (entity && entity.uuid && entity.uuid.length > 0) {
          setUuidValid(true)
        } else {
          setUuidValid(false)
        }
        if (entity && entity.booked_date) {
          setBooked(true)
          setSelectedDate(moment(entity.booked_date, 'YYYY-MM-DD'))
          setSelectedTime(entity.booked_time)
          setName(entity.booked_name)
          setEmail(entity.booked_email)
          setTelephone(entity.booked_telephone)
        } else {
          if (availability.length > 0) {
            setSelectedDate(availability[0].date)
          }
        }
        setLoading(false)
      }).catch(handleError)
    }
  }, [uuid]);

  useEffect(() => {
    if (!selectedDate && availability.length > 0) {
      setSelectedDate(availability[0][0].date)
    } else if (selectedDate && availability.length > 0) {
      for (let av of availability) {
        const match = _.find(av, a => a.date_str === selectedDate.format('YYYY-MM-DD'))
        if (match) {
          setTimeslots(match.times)
          break
        }
      }
    }
  }, [selectedDate, availability])
  
  return (
    <div className="App">
      <div className="container st-cont">
        <img src={'/assets/images/logo.png'} alt='Essential English Centre' width={150} />
        {uuidValid === false ?
          <div className="st-step after-eli">
            <div className="st-card pd-sm">
              <Alert variant="danger" show={true}>UUID Not Found</Alert>
            </div>
          </div>
        :
          <>
            <div className="st-step after-eli">
              <div className="st-card pd-sm">
                <Alert variant="danger" show={requestError}>{requestError}</Alert>
                <h1 className="fnt-medium title">Book an Appointment</h1>
                {loading ?
                  <CirclesWithBar
                    height="100"
                    width="100"
                    color="#4fa94d"
                    wrapperStyle={{justifyContent: 'center', marginVertical: 16}}
                    wrapperClass=""
                    visible={true}
                    outerCircleColor=""
                    innerCircleColor=""
                    barColor=""
                    ariaLabel='circles-with-bar-loading'
                  /> 
                : null}
                {/* <h2>Select Course</h2> */}
                <div className="row branch-cont">
                  {types.map((c, i) => {
                    const active = selectedApptType.title === c.title 
                    return (
                      <div key={i} className="col-md-6">
                        <div className={`dashed-cont ${active ? 'active' : ''}`} onClick={() => {
                          onApptType(c)
                        }}>
                          <div className={`branch-sel ${active ? 'active' : ''}`}>
                            <span>{c.title}</span><br />
                            <span>{c.subtitle}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="text-lg pb-sm">Select a time</div>
                <div className="slides" id="calendar-slider">
                  {availability.map((p, i) => {
                    return (
                      <div key={`${p.date_str}-${i}`} id={`dates-${i}`} className="btn-cont">
                        <div className="date-nav prev">
                          <a className='slide-nav-btn' href={`#dates-${i-1}`} onClick={() => {
                            slideNavPress('prev');
                          }}>
                            <svg data-v-601aceee="" id="specs-chevron-icon-left" width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg" className="specs-icon-chevron day-select-ribbon__chevron-icon">
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.0631 15.0631C7.58054 15.5805 8.41947 15.5805 8.9369 15.0631C9.45481 14.5452 9.45427 13.7053 8.93571 13.1881L3.73438 8L8.93749 2.79688C9.45463 2.27975 9.45287 1.44077 8.93358 0.925797C8.41735 0.413868 7.58439 0.415611 7.0703 0.929696L0.0792074 7.92079C0.0354624 7.96454 0.0354624 8.03546 0.0792084 8.07921L7.0631 15.0631Z" className="specs-icon-chevron--color-disabled"></path>
                            </svg>
                          </a>
                        </div>
                        {p.map((d, ii) => {
                          return (
                            <div key={`dates-${d.date_str}-${ii}`} className={`date-btn ${(selectedDate && d.date_str === selectedDate.format('YYYY-MM-DD') ? 'active' : '')}`} onClick={() => {
                              onSelectDate(d.date);
                            }}>
                              <div>{d.month}</div>
                              <b>{d.day}</b>
                            </div>
                          )
                        })}
                        <div className="date-nav next">
                          <a className='slide-nav-btn' href={`#dates-${i+1}`} onClick={() => {
                            slideNavPress('next');
                          }}>
                            <svg data-v-601aceee="" id="specs-chevron-icon-right" width="10" height="16" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg" className="specs-icon-chevron day-select-ribbon__chevron-icon">
                              <path fillRule="evenodd" clipRule="evenodd" d="M2.8119 0.936902C2.29446 0.419465 1.45553 0.419466 0.938097 0.936903C0.420194 1.45481 0.420729 2.29466 0.939291 2.8119L6.14062 8L0.937509 13.2031C0.420373 13.7203 0.422128 14.5592 0.941423 15.0742C1.45765 15.5861 2.29061 15.5844 2.8047 15.0703L9.79579 8.07921C9.83954 8.03546 9.83954 7.96454 9.79579 7.92079L2.8119 0.936902Z" className="specs-icon-chevron--color-default"></path>
                            </svg>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div id="calendar-details">
                  <div className="calendar-date"></div>
                  <div className="calendar-times row">
                    {timeslots.map(t => {
                      return (
                        <div key={t.time} className="col-3 col-xs-6 col-sm-3 col-md-2">
                          <div className={`calendar-time ${(t.disabled ? ' disabled' : '')} ${selectedTime === t.time ? ' active' : ''}`} onClick={() => {
                            onSelectTime(t)
                          }}>{t.time}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            {selectedApptType && selectedDate && selectedTime ?
              <div className="st-step">
                <div className="st-card">
                  <h1>{booked ? 'Your Appointment Recap' : 'Your Appointment Summary'}</h1>

                  <label className="st-label subtitle">Appointment Type</label>
                  <h2 id="branch">{selectedApptType.title}</h2>
                  <span id="branch-addr"></span><br />
                  <div className="divider"></div>

                  <label className="st-label subtitle">When</label>
                  <h2>{selectedTime} - {selectedDate.format('ddd, Do MMMM, YYYY')}</h2>
                  <span>Your level test will take place at The Essential English Centre, 109 Portland Street, Manchester, M1 6DN.</span><br />
                  <br />
                  <span>No longer able to make it?</span>&nbsp;
                  <a href="# " onClick={e => {
                    e.preventDefault();
                    onCancelBooking();
                  }}>Cancel Booking</a>
                  <div className="divider"></div>

                  <label className="st-label subtitle">Your Details</label>
                  <div className="form-group">
                    <label className="st-label" htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label className="st-label" htmlFor="telephone">Telephone</label>
                    <input type="phone" className="form-control" id="telephone" placeholder="" value={telephone} onChange={e => setTelephone(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label className="st-label" htmlFor="email">E-Mail</label>
                    <input type="email" className="form-control" id="email" placeholder="" value={email} onChange={e => setEmail(e.target.value)} />
                  </div>
                </div>
                <div className="btn-cont show">
                  {loading ?
                    <CirclesWithBar
                      height="100"
                      width="100"
                      color="#4fa94d"
                      wrapperStyle={{justifyContent: 'center', marginTop: 16}}
                      wrapperClass=""
                      visible={true}
                      outerCircleColor=""
                      innerCircleColor=""
                      barColor=""
                      ariaLabel='circles-with-bar-loading'
                    />
                  :
                    <Button variant="success" className="btn-appt-time" onClick={onSubmitBooking}>Submit</Button>
                  }
                </div>
              </div>
            : null}          
          </>
        }
      </div>
    </div>
  );
}

export default App;
